import { setFakeToken, removeFakeToken, getToken } from "../util.js";
import UserProt from "./Prot/UserProt.ts";

const state = {
  Loading: false,
  infoData: {
    token: "",
    uid: "",
    email: "",
    IsDemo: 0,
    balance: 0,
    lock_balance: 0,
  },
  symbolData: {},
};

const mutations = {
  SetLoading: (state, v) => (state.Loading = v),
  SetInfoData: (state, v) => {
    Object.assign(state.infoData, v);
  },
  SetSymbolData: (state, v) => (state.symbolData = v),
  SetRegInfoData: (state) => {
    state.infoData = {};

  },

};

const getters = {
  GetInfoData: (state) => state.infoData,
  GetLoding: (state) => state.Loading,
  GetSymbolData: (state) => state.symbolData,
};

const actions = {
  // 登录
  async Login({ commit, state }, { data }) {
    commit("SetLoading", true);
    commit("SetInfoData", await UserProt.Login(data));
    setFakeToken(state.infoData.token);
  },

  // 注册
  async Reg({ commit }, { data }) {
    await UserProt.Reg(data);
  },

  // 试玩注册
  async DemoReg({ commit,state }){
    commit("SetLoading", true);
    commit("SetInfoData", await UserProt.DemoReg());
    setFakeToken(state.infoData.token);
  },

  // 获取用户数据
  async GetUserData({ commit }) {
    commit("SetInfoData", await UserProt.GetUserData());
  },

  // 获取订单
  async GetUserOrderList({ commit }, { data }) {
    return await UserProt.GetUserOrderList(data);
  },

  // 获取所有产品
  async GetAreaData({ commit }, { data }) {
    console.log(data.cancelTokenSource)
    return await UserProt.GetAreaData(data);
  },

  // 获取合约配置
  async GetContData({ commit }, { data }) {
    return await UserProt.GetContData(data);
  },

  // 秒合约买入卖出
  async SDealCont({ commit }, { data }) {
    return await UserProt.SDealCont(data);
  },

  // 平仓挂单买入卖出
  async DealCont({ commit }, { data }) {
    await UserProt.DealCont(data);
  },

  // 获取秒合约详细
  async GetSDealOrder({ commit }, { data }) {
    return await UserProt.GetSDealOrder(data);
  },

  // 获取新闻列表
  async GetNewList() {
    return await UserProt.GetNewList();
  },

  // 获取新闻详情
  async GetNewDetail({ commit }, { data }) {
    return await UserProt.GetNewDetail(data);
  },

  // 获取资金记录
  async GetMoneyRecord({ commit }, { data }) {
    return await UserProt.GetMoneyRecord(data);
  },

  // 获取充值货币种类
  async GetRechargeSymbolList() {
    return await UserProt.GetRechargeSymbolList();
  },

  // 获取充值货币种类
  async GetWithdrawSymbolList() {
    return await UserProt.GetWithdrawSymbolList();
  },

  // 获取提现货币
  async GetUsdt() {
    return await UserProt.GetUsdt();
  },

  async GetDataByType({ commit }, { data }) {
    return await UserProt.GetDataByType(data);
  },

  // 获取订单列表
  async GetOrderList({ commit }, { data }) {
    return await UserProt.GetOrderList(data);
  },

  // 退出登录
  async LogOut({ commit }) {
    localStorage.setItem("token", "");
    localStorage.setItem("vuex", "");
    await UserProt.LogOut();
    commit('SetRegInfoData')
  },

  // 获取修改密码的验证码
  async GetVerificationCode({ commit }, { data }) {
    return await UserProt.GetVerificationCode(data);
  },

  // 修改密码
  async UpPassWord({ commit }, { data }) {
    await UserProt.UpPassWord(data);
  },

  //获取分享地址
  async GetShare() {
    return await UserProt.GetShare();
  },
  
  // 修改 止损止盈
  async UpLossProfit({ commit },{ data }){
    await UserProt.UpLossProfit(data);
  },

  async Pingc({ commit },{ data }){
    await UserProt.Pingc(data)
  },

  async AddBank({ commit },{ data }){
    await UserProt.AddBank(data);
  },

  async GetServiceUrl({ commit }){
    return UserProt.GetServiceUrl();
  }

};

export default {
  state,
  getters,
  mutations,
  actions,
};
