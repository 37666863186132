<template>
    <div style="min-height: 100vh; flex: 1;" v-loading="$store.getters.GetLoding">
        <keep-alive v-if="isCached">
            <router-view v-slot="{ Component }">
                <transition :name="ExcessName">
                    <component :is="Component"></component>
                </transition>
            </router-view>
        </keep-alive>

        <router-view v-else v-slot="{ Component }">
            <transition :name="ExcessName">
                <component :is="Component"></component>
            </transition>
        </router-view>

        <van-dialog v-model:show="showDialog" theme="round-button" class-name="restrict-class"
            @close="handleDialogClose" :title="$t('title-welcome_to_use')"
            :message="$t('announce-text') + setRedTxt($t('announce-text-demo'))"
            :confirm-button-text="confirmButtonText" :confirm-button-disabled="confirmDisabled" allowHtml="true" />
    </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const ExcessName = ref("");
const showDialog = ref(false);
const confirmButtonText = ref(t("public-btn-accept") + " (10)");
const confirmDisabled = ref(true);

// 判断是否需要缓存
const isCached = computed(() => {
    return router.currentRoute.value.meta.keepAlive; // 从路由的 meta 中获取
});

const isLogin = computed(() => store.getters["isLogin"]);
const theme = computed(() => store.getters.theme);
const userInfo = computed(() => {
    const userInfoString = localStorage.getItem("userInfo");
    return userInfoString ? JSON.parse(userInfoString) : null;
});
const isDemo = computed(() => (userInfo.value ? userInfo.value.IsDemo : null));

onMounted(() => {
    // 获取主题颜色
    const savedTheme = localStorage.getItem("theme") || "light";
    document.documentElement.setAttribute("data-theme", savedTheme);
    store.dispatch("setTheme", savedTheme);

    // 首次进入显示的弹窗
    showRestrictPopup();
});

// 退出登录
const logOut = () => {
    store.dispatch("setLogOut");
    router.push("/");
};

// 设置红色文本
const setRedTxt = (val) => {
    return `<span class="text-kline-down">${val}</span>`;
};

// 显示限制弹窗
const showRestrictPopup = () => {
    if (!localStorage.getItem("isFirstVisit")) {
        showDialog.value = true;

        // 10秒倒数
        let countdown = 10;
        const interval = setInterval(() => {
            countdown--;
            confirmButtonText.value = t("public-btn-accept") + ` (${countdown})`;

            if (countdown === 0) {
                clearInterval(interval);
                confirmButtonText.value = t("public-btn-accept");
                confirmDisabled.value = false;
            }
        }, 1000);

        localStorage.setItem("isFirstVisit", "false");
    }
};

// 处理对话框关闭
const handleDialogClose = () => {
    showDialog.value = false;
};

// 更新过渡效果
function updateTransition(to, from) {
    if (to.name === "HomeIndex") {
        ExcessName.value = "LfExcess";
    } else if (to.name === "OrderIndex") {
        ExcessName.value = from.name === "HomeIndex" ? "RgExcess" : "LfExcess";
    } else if (to.name === "TradeIndex") {
        ExcessName.value = from.name === "HomeIndex" || from.name === "OrderIndex" ? "RgExcess" : "LfExcess";
    } else if (to.name === "NewsIndex") {
        ExcessName.value = from.name === "MineIndex" ? "LfExcess" : "RgExcess";
    } else if (to.name === "MineIndex") {
        ExcessName.value = "RgExcess";
    } else {
        ExcessName.value = "LfExcess";
    }
}

// 处理路由变化时的过渡效果
router.beforeEach((to, from) => {
    updateTransition(to, from);
});
</script>

<style>
html,
body,
#app {
  margin: 0 !important;
  /* background: white; */
  background-attachment: fixed;
  background-position: center top;
  background-size: 100% 100%;
  width: 100%;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    Segoe UI, Arial, Roboto, "PingFang SC", "miui", "Hiragino Sans GB",
    "Microsoft Yahei", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-enter-from .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* 右进左出效果 */
.RgExcess-enter-active,
.RgExcess-leave-active {
  transition: opacity 0.3s ease;
}

.RgExcess-enter,
.RgExcess-leave-to {
  opacity: 0;
}

.RgExcess-leave-active {
  opacity: 0;
}

/* 左进右出效果 */
.LfExcess-enter-active,
.LfExcess-leave-active {
  transition: opacity 0.3s ease;
}

.LfExcess-enter,
.LfExcess-leave-to {
  opacity: 0;
}

.LfExcess-leave-active {
  opacity: 0;
}
</style>
