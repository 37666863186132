export default {
  "app-title": "FBS",
  "app-title-2": "Không bán",
  "app-title-language": "Ngôn ngữ",
  "title-detail": "Chi tiết",
  "title-welcome_to_use": "Chào mừng bạn sử dụng",

  //tabBar
  "tabbar-market": "Thị trường",
  "tabbar-order": "Đơn hàng",
  "tabbar-news": "Tin tức",
  "tabbar-mine": "Tài khoản của tôi",
  "public-night_mode": "Chế độ ban đêm",
  "public-noRecord": "Chưa có dữ liệu",
  "public-text-cancel": "Hủy",
  "public-text-confirm": "Xác nhận",
  "public-text-balance": "Số dư",
  "public-text-date": "Ngày",
  "public-text-completed": "Hoàn tất",
  "public-text-invalid": "Không hợp lệ",
  "public-text-unchecked": "Chưa kiểm tra",
  "public-text-remarks": "Ghi chú",
  "public-text-loading": "Đang tải",
  "public-text-loose-to-refresh": "Thả để làm mới...",
  "public-text-buy": "Mua",
  "public-text-sell": "Bán",
  "public-text-close": "Đóng",
  "public-text-lots_1": "Lô",
  "public-text-lots": "Lô",
  "public-text-available": "Có sẵn",
  "public-text-freeze": "Đóng băng",
  "public-text-scroll_down_refresh": "Kéo xuống để làm mới...",
  "public-btn-submit": "Gửi",
  "public-btn-add": "Thêm",
  "public-btn-copy": "Sao chép",
  "public-btn-delete": "Xóa",
  "public-btn-copyLink": "Sao chép liên kết mời",
  "public-btn-send": "Gửi",
  "public-btn-download": "TẢI XUỐNG",
  "public-btn-resetPassword": "Đặt lại mật khẩu",
  "public-btn-confirmation": "Xác nhận",
  "public-btn-accept": "Chấp nhận",
  "public-enterEmail": "Nhập email của bạn",
  "public-password": "Mật khẩu",
  "public-retypePassword": "Nhập lại mật khẩu",
  "public-inviteCode": "Mã mời",
  "public-verifyCode": "Mã xác minh",
  "login-newUser": "Người dùng mới?",
  "login-joinNow": "Tham gia ngay",
  "login-forgotPassword": "Quên mật khẩu",
  "login-btn-login": "Đăng nhập",
  "login-btn-demoAcc":
    "Không cần mở tài khoản, tạo tài khoản demo để giao dịch ngay!",
  "login-enterEmail": "Vui lòng nhập địa chỉ email của bạn",
  "login-enterPassword": "Vui lòng nhập mật khẩu của bạn",
  "regsiter-btn-register": "Đăng ký",
  "regsiter-enterInviteCode": "Vui lòng nhập mã mời",
  "regsiter-enterVerityCode": "Vui lòng nhập mã xác minh",
  "logout-title": "Đăng xuất",
  "systemHint-reset_successful": "Đặt lại thành công",
  "systemHint-incorrectVerityCode": "Mã xác minh không chính xác",
  "systemHint-passwordsDoNotMatch": "Mật khẩu không khớp",
  "systemHint-password_error": "Lỗi mật khẩu",
  "systemHint-login_connot_find_user": "Không tìm thấy người dùng này",
  "systemHint-copySuccess": "Sao chép thành công",
  "systemHint-plsCompleted": "Vui lòng hoàn tất",
  "systemHint-pwdInconsistent": "Mật khẩu không nhất quán",
  "systemHint-incorrectVerifyCode": "Mã xác minh không chính xác",
  "systemHint-changeSuccess": "Thay đổi thành công",
  "systemHint-plsSubmit": "Vui lòng gửi thông tin đầy đủ",
  "systemHint-binb-bank-not-open-yet":
    "Chưa mở chức năng rút tiền cho loại tiền này",
  "systemHint-plsFillIn": "Vui lòng điền đầy đủ thông tin",
  "systemHint-requestSuccessful": "Yêu cầu thành công",
  "systemHint-operation_successful": "Hoạt động thành công",
  "systemHint-set_successful": "Thiết lập thành công",
  "systemHint-missing_params-or-val-error": "Thiếu tham số hoặc lỗi giá trị",
  "systemHint-limit_trade_price_must_greater":
    "Giá giao dịch hạn chế phải lớn hơn 0",
  "systemHint-buy-stop_loss_price_higher_than_opening_price":
    "Giá dừng lỗ khi mua (dài) không được cao hơn giá mở cửa",
  "systemHint-buy-stop_profit_price_higher_than_opening_price":
    "Giá dừng lợi nhuận khi mua (dài) không được cao hơn giá mở cửa",
  "systemHint-sell-stop_loss_price_lower_than_opening_price":
    "Giá dừng lỗ khi bán (ngắn) không được thấp hơn giá mở cửa",
  "systemHint-sell-stop_profit_price_lower_than_opening_price":
    "Giá dừng lợi nhuận khi bán (ngắn) không được thấp hơn giá mở cửa",
  "systemHint-code_send_succesful": "Gửi mã xác minh thành công",
  "systemHint-pls_register_login_real_account":
    "Vui lòng đăng ký hoặc đăng nhập vào tài khoản thực",
  "systemHint-successful_exit": "Thoát thành công",
  "popupHint-set_up_collection_account":
    "Vui lòng thiết lập tài khoản thu tiền trước",
  "popupHint-confirm_to_delete": "Bạn có chắc chắn muốn xóa không?",
  "popupHint-whether_return_it": "Có cần trả lại không",
  "mine-availableAssets": "Tài sản có sẵn",
  "mine-account_details": "Chi tiết tài khoản",
  "mine-deposit": "Nạp tiền",
  "mine-withdraw": "Rút tiền",
  "mine-billDetails": "Chi tiết hóa đơn",
  "mine-demo_return_simulated_account": "Quay lại tài khoản giả lập",
  "mine-wallet": "Ví",
  "mine-verifyName": "Xác minh tên thật",
  "mine-inviteFriend": "Mời bạn bè",
  "mine-changePassword": "Thay đổi mật khẩu",
  "mine-onlineSerive": "Dịch vụ trực tuyến",
  "mine-complaintEmail": "Email khiếu nại",
  "mine-announcement": "Thông báo",
  "mine-establishment": "Thành lập",
  "mine-other": "Khác",
  "mine-change_theme": "Thay đổi chủ đề",
  "dialog-title": "Nhắc nhở",
  "dialog-logout-conetnt": "Bạn có chắc chắn muốn đăng xuất không?",
  "announce-text":
    "Do lý do chính sách, dịch vụ không được cung cấp cho Triều Tiên, Israel, Trung Quốc, Vanuatu và Cuba.",
  "announce-text-demo": "(FBS Chỉ để kiểm tra)",
  "verify-text-upload":
    "Vui lòng tải lên mặt trước và mặt sau của hộ chiếu/CMND của bạn",
  "verify-front": "Mặt trước",
  "verify-front-desc": "Tải lên mặt trước của hộ chiếu/chứng minh thư của bạn",
  "verify-back": "Mặt sau",
  "verify-back-desc": "Tải lên mặt sau của hộ chiếu/chứng minh thư của bạn",
  "verify-text-info": "Vui lòng hoàn thiện thông tin cá nhân của bạn",
  "verify-name": "Tên",
  "verify-enterName": "Vui lòng nhập tên của bạn",
  "verify-passportId": "Số hộ chiếu/CMND",
  "verify-enterPassportId": "Vui lòng nhập số hộ chiếu/CMND của bạn",
  "verify-infoAuth": "Xác thực",
  "wallet-bind-bank": "Liên kết thẻ ngân hàng",
  "wallet-bind-currency-address": "Liên kết địa chỉ tiền kỹ thuật số",
  "bind-bank-name": "Tên ngân hàng",
  "bind-bank-address": "Địa chỉ ngân hàng",
  "bind-bank-swift": "SWIFT",
  "bind-bank-payee": "Người nhận",
  "bind-bank-payee-account": "Tài khoản người nhận",
  "bind-currency": "Tiền tệ",
  "bind-wallet-address": "Địa chỉ ví",
  "deposit-digitai-currency": "Tiền kỹ thuật số",
  "deposit-bank-card": "Thẻ ngân hàng",
  "deposit-select_network": "Chọn mạng",
  "deposit-coin-number": "Số lượng tiền",
  "deposit-coin-placeholder": "Vui lòng nhập số lượng tiền gửi",
  "doposit-upload-cert": "Tải lên chứng chỉ",
  "deposit-record": "Lịch sử nạp tiền",
  "deposit-recharge_amount": "Số tiền nạp",
  "deposit-recharge-placeholder": "Vui lòng nhập số tiền nạp",
  "deposit-recharge_type": "Loại",
  "deposit-recharge_unit": "Đơn vị",
  "deposit-recharge_status": "Trạng thái",
  "deposit-receipt_currency": "Tiền tệ nhận",
  "withdraw-coin_address": "Địa chỉ rút tiền",
  "withdraw-coin_amount": "Số lượng",
  "withdraw-handling_fee": "Phí xử lý",
  "withdraw-expected": "Số tiền dự kiến được ghi nhận",
  "withdraw-btn-submit": "Gửi yêu cầu rút tiền",
  "withdraw-coin_withdrawal_record": "Lịch sử rút tiền",
  "withdraw-num_of_receipt": "Số lượng biên lai",
  "withdraw-select_addess": "Vui lòng chọn địa chỉ để gọi",
  "order-position_holding": "Giữ vị trí",
  "order-pending_orders": "Đơn hàng đang chờ",
  "order-history": "Lịch sử",
  "order-profit_loss": "Lợi nhuận và lỗ",
  "order-current_margin": "Mức ký quỹ hiện tại",
  "order-risk_rate": "Tỷ lệ rủi ro",
  "order-order_id": "ID đơn hàng",
  "order-total_profit_loss": "Lợi nhuận và lỗ tổng cộng",
  "order-order_details": "Chi tiết đơn hàng",
  "order-margin": "Ký quỹ",
  "order-take_profit": "Lấy lợi nhuận",
  "order-set_loss": "Đặt lỗ",
  "order-set_TPSL": "Đặt TP/SL",
  "order-close_position": "Đóng vị trí",
  "order-cancel_order": "Hủy đơn hàng",
  "order-select_period": "Chọn thời gian",
  "order-btn_rise": "Tăng",
  "order-btn_fall": "Giảm",
  "order-estimated_return": "Lợi nhuận ước tính",
  "order-purchase_amount": "Số tiền mua",
  "search-search_product": "Tìm sản phẩm",
  "search-search_result": "Kết quả tìm kiếm",
  "trade-market_price": "Giá thị trường",
  "trade-price": "Giá",
  "trade-quick_trade": "Giao dịch nhanh",
  "trade-please_enter_price": "Vui lòng nhập giá",
  "trade-each_lots": "Mỗi lô",
  "trade-estimated_handling_fee": "Phí xử lý ước tính",
  "trade-estimated_margin": "Ký quỹ ước tính",
  "trade-lots_text": "Lô (Bước: 0,01)",
  "trade-order_page": "Trang đơn hàng",
  "trade-your_order_has_been_confirmed": "Đơn hàng của bạn đã được xác nhận",
  "trade-multiplier": "Hệ số",
  "accHistory-financial_records": "Hồ sơ tài chính",
  "accHistory-records": "Hồ sơ",

  Res_Msg1: "Vui lòng nhập email",
  Res_Msg2: "Định dạng email không hợp lệ",
  Res_Msg3: "Vui lòng nhập mật khẩu",
  Res_Msg4: "Mật khẩu và xác nhận mật khẩu không khớp",
  Res_Msg5: "Mật khẩu phải có ít nhất 6 ký tự",
  Res_Msg6: "Mã xác nhận không chính xác",
  Res_Msg7: "Vui lòng nhập mã xác nhận",
  Res_su: "Đăng ký thành công",
  Login_su: "Đăng nhập thành công",
  Loading: "Không còn dữ liệu nữa",
  SDealCont_TopName: "Giao hàng",
  SDealCont_L1: "Giá mua",
  SDealCont_L2: "Giá hiện tại",
  SDealCont_L3: "Hướng",
  SDealCont_L4: "Số lượng",
  SDealCont_L5: "Thời gian giao hàng",
  SDealCont_btn: "Đóng",
  SDealCont_L6: "Mua dài",
  SDealCont_L7: "Bán ngắn",
  SDealCont_L8: "Mua",
  SDealCont_L9: "Bán",
  "verify-verified": "Xác minh thành công",
  "verify-notVerified": "Xác minh không thành công",
  "加载中...": "Đang tải...",
  "分时":"Thời gian"
};
